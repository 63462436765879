import React from "react";
import Item from "./Item";
import "./Checklist.css";
import List from "@material-ui/core/List"
import SubcategoryTile from "./SubcategoryTile";

class Checklist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "Checklist name",
            items: [],
            subcategories: {}
        };
        this.downloadList();
    }

    downloadList() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let checklistId = params.get('checklistId');

        if (checklistId == null) {
            console.log("No query param");
            checklistId = "e2jg1yrgnnk";
        }

        var checklist = {};
        fetch('https://server.medicalcrosscheck.com/Server/Adapters/getList.php',
            {
                method: 'POST',    
                body: JSON.stringify({
                    checklistId: checklistId
                })
            }
        )
        .then(res => res.json())
        .then((data) => {
            checklist = data.checklist;
            var subcategoryMap = checklist.subcategories.reduce(
                function(map, obj) {
                    map[obj.identifier] = obj;
                    return map;
                }, {});
            this.setState({
                title: checklist.title,
                items: checklist.items,
                subcategories: subcategoryMap
            });
        });
        return checklist;
    }

    getItems() {
        var lastSubcat = "";
        var i;
        var componentList = [];
        for (i = 0; i < this.state.items.length; i++) {
            var item = this.state.items[i];

            if (item.subcategoryIdentifier !== lastSubcat) {
                var subcategoryName = this.state.subcategories[item.subcategoryIdentifier].name;
                var subcategoryId = this.state.subcategories[item.subcategoryIdentifier].identifier;
                lastSubcat = item.subcategoryIdentifier;
                componentList.push(
                    <SubcategoryTile key={subcategoryId} name={subcategoryName}/>
                );
            }
            componentList.push(<Item item={item}/>);
        }
        return componentList;
    }

    render() {
        return (
            <div>
                <h2>{this.state.title}</h2>
                <div className="ChecklistItemsView">
                    <List className="ChecklistItems"
                        component="nav"
                    >
                        {this.getItems()}
                    </List>
                </div>            
            </div>
        )
    }
}

export default Checklist;