import React from "react";
import "./Item.css"
const { ListItem, ListItemText } = require("@material-ui/core");

class SubcategoryTile extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {title: props.name};
    }

    render() {
        return (
            <div className="subcategoryBox" style={{display:"flex"}}>
                <ListItem divider>
                    <ListItemText className="subcategoryText" primary={this.state.title}></ListItemText>
                </ListItem>
            </div>
        );
    }
}

export default SubcategoryTile;