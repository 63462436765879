import React from "react";
import "./Item.css";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Checkbox } from "@material-ui/core";

class Item extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {item: props.item};
    }

    render() {
        return (
            <div className="checkboxRow" style={{display:"flex"}}>
            <ListItem button divider className="checklistItemName" 
                key={this.state.item.id}>
                    <Checkbox color="secondary"></Checkbox>
                    <ListItemText primary={this.state.item.title}/>                    
            </ListItem>
        </div>
        );
    }
}



export default Item;