import './App.css';
import React, {useState} from "react";
import Checklist from './components/Checklist';
import logo from "./graphics/icon_small.png";

function App() {
  const [todos, setTodos] = useState([]);

  function addTodo(todo) {
    setTodos([todo, ...todos]);
  }

  return (
    <div className="Crosscheck">
      <div className="mainHeader">
        <h1>Medical Crosscheck</h1>
        <img className="headingLogo" src={logo} alt="Medical Crosscheck"/>
      </div>
      <Checklist/>
    </div>
  );
}

export default App;
